.policy-section {
  position: relative;
  padding-top: 7.7rem;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 77.9rem;
    height: 64.6rem;
    background-image: url('./../img/logo-bg.svg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__header {
    margin: 0 auto;
    margin-bottom: 4rem;
    text-align: center;

    &>h1 {
      margin: 0;
      margin-bottom: 2rem;
      color: #000;
      leading-trim: both;
      text-edge: cap;
      font-family: "Oswald";
      font-size: 4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    &>h2 {
      margin: 0;
      color: #000;
      font-family: "Oswald";
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__container {
    padding-bottom: 10rem;

    &>h3 {
      margin: 0;
      color: #000;
      font-family: "Oswald";
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }

    &>p {
      margin: 0;
      color: #000;
      font-family: "Roboto";
      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }

    &>ul>li {
      font-size: 2rem;
    }
  }
}