.discount {
  background-color: #183475;
  padding: 8rem 0;
  position: relative;
  z-index: 15;

  @include mobile {
    padding-top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 7.9rem;
    transform: translate(-50%, 0);

    height: 64.6rem;
    width: 77.9rem;

    background-image: url('./../img/logo-bg.svg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @include mobile {
      display: none;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @include mobile {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-bottom: 12.2rem;
    }
  }

  &__container {
    max-width: 117rem;
    margin: 0 auto;
  }

  &__title {
    margin-left: -12rem;
    height: unset;
    margin-bottom: 2rem;

    &::before {
      content: "скидка";
    }

    @include mobile {
      margin-left: -1.6rem;
      margin-right: -1.6rem;
      margin-bottom: 5rem;
      max-width: unset;
    }
  }

  &__desc {
    align-self: flex-end;
    position: relative;
    margin: 0;
    color: #FFF;
    max-width: 50.2rem;
    text-shadow: 0.8rem 0.6rem 1rem rgba(0, 0, 0, 0.35);
    font-family: "Oswald";
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    @include mobile {
      font-size: 2.8rem;
      max-width: 28.1rem;
      align-self: unset;
    }

    span {
      font-size: 12.8rem;
      line-height: 1.15;

      @include mobile {
        position: absolute;
        top: 35%;
        right: 0;
        font-size: 9.6rem;
        transform: translateX(65%);
      }
    }
  }
}


.disc-form {
  position: relative;
  z-index: 5;

  @include mobile {
    padding: 0 1.5rem;
  }

  &__list {}

  &__item {

    &:not(:last-child) {
      margin-bottom: 5rem;

      @include mobile {
        margin-bottom: 4rem;
      }
    }
  }

  &__question {
    margin: 0;
    margin-bottom: 3rem;
    color: #FFF;
    font-family: "Roboto";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include mobile {
      margin-bottom: 2rem;
    }

  }

  &__label {
    position: relative;
    // padding-left: 4.5rem;
    display: flex;
    align-items: center;
    gap: 3rem;
    cursor: pointer;
    user-select: none;

    @include mobile {
      width: 100%;
    }

    input[type="checkbox"] {
      cursor: pointer;
      background: #FFF;
      box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
    }

    span {
      color: #FFF;
      font-family: "Roboto";
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--unspace {
      justify-content: unset;
      gap: 10rem;

      .disc-form__label {
        @include mobile {
          width: auto;
        }
      }

      @include mobile {
        gap: unset;
        justify-content: space-between;
      }
    }

    @include mobile {
      flex-wrap: wrap;
      gap: 2rem;
    }
  }

  &__input {
    padding: 1rem;
    background-color: unset;
    border: none;
    border-bottom: 0.2rem solid #FFF;
    width: 100%;
    color: #fff;
    font-family: "Roboto";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    &--address {
      width: auto;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.30);
    }

    &--address {
      flex: 1;
    }
  }

  &__btn {
    cursor: pointer;
    text-align: center;

    @include mobile {
      margin: 0 auto;
    }
  }
}
