.rent {
  padding-top: 12rem;
  padding-bottom: 17rem;

  @include mobile {
    padding-top: 8.4rem;
    padding-bottom: 8.8rem;
  }

  &__image {
    @include mobile {
      display: none;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__title {
    &::before {
      content: "Аренда";
    }

    @include mobile {
      width: 100vw;
      max-width: unset;
      margin-bottom: 5rem;
      margin-left: -1.6rem;
      margin-right: -1.6rem;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include mobile {
      grid-template-columns: minmax(0, 1fr);
      gap: 2rem 0;
    }
  }
}

.rent-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 12.3rem;
  padding-bottom: 5rem;
  background-color: #FFF;

  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
  transition: background-color .3s ease-in;

  @include mobile {
    height: 68rem;
  }


  @include mobile {
    padding: 3rem 1.9rem;
    padding-bottom: 3.4rem;
  }

  &__title {
    margin: 0;
    margin-bottom: 4rem;
    color: #403D3A;
    text-align: center;
    font-family: "Oswald";
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.096rem;
    text-transform: uppercase;
    transition: color .3s ease-in;

    @include mobile {
      margin-bottom: 6rem;
    }
  }

  &__list {
    margin-bottom: 2.6rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    position: relative;
    padding-left: 4rem;
    color: #403D3A;
    font-family: "Roboto";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: color .3s ease-in;

    &>span {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;

      svg {
        width: 100%;
        height: 100%;
        fill: #183475;

        transition: fill .3s ease-in;
      }
    }

  }

  &__btn {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 37.5rem;
    height: 7rem;
    margin-top: auto;
    padding: 1rem 2rem;
    color: #FFF;
    background-color: #294B9B;
    text-align: center;
    font-family: "Roboto";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      inset: 0;
      background-color: unset;
      transform: unset;
    }

    @include mobile {
      width: 100%;
    }
  }


  &:hover {
    background: #183475;


    .rent-item__item {
      color: #fff;

      svg {
        fill: #fff;
      }
    }

    .rent-item__title {
      color: #fff;
    }

    .rent-item__btn {
      background-color: #294B9B;
      color: #fff !important;
    }
  }
}