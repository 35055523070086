.partners {
  &__title {
    display: flex;
    align-items: center;
    height: 15rem;
    width: 100%;
    max-width: 130rem;

    margin: 0;
    margin-bottom: 3.4rem;


    padding-left: 12rem;

    position: relative;
    z-index: 2;

    color: #fff;
    font-family: "Oswald";
    font-size: 4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12rem;
    text-transform: uppercase;

    @include mobile {
      height: unset;
      font-size: 4rem;
      padding-left: unset;
      width: 100%;
      margin-bottom: 10rem;
    }

    &::before {
      content: "Наши партнеры";
      position: absolute;
      white-space: nowrap;
      z-index: -1;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      color: rgba(255, 255, 255, 0.05);
      leading-trim: both;
      text-edge: cap;
      font-family: "Oswald";
      font-size: 18.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;

      @include mobile {
        font-size: 6.4rem;
        top: 25%;
        transform: translate(0, -75%);
        margin-left: -1.6rem;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 3rem 5rem;
    width: 50rem;
    height: 11rem;

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transform: translateY(110%);
      transition: transform .3s ease-out;
    }

    svg {
      height: 5rem;
      fill: #fff;
      transition: fill .3s ease-out, stroke .3s ease-out;
    }

    &--auto {
      svg {
        fill: none;
        stroke: #fff;
      }

      &:hover {
        svg {
          fill: none !important;
          stroke: #183475;
        }
      }
    }

    &--rus {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      svg {
        flex-shrink: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    @include mobile {
      padding-right: 0;
      padding-left: 0;
      width: 31.7rem;
    }

    &:hover {
      &::after {
        transform: translateY(0);
      }

      svg {
        fill: #183475;
      }
    }
  }
}

.items-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  border-top: 1px solid #fff;

  @include mobile {
    border-bottom: 1px solid #fff;
  }

  &:last-child {
    border-bottom: 1px solid #fff;

    @include mobile {
      display: none;
    }
  }
}



.items {
  flex-shrink: 0;
  display: flex;
  min-width: 100%;
}

.marquee {
  animation: scroll 20s linear infinite;
}

.reverce {
  animation-direction: reverse;
}

.items-wrap:hover .marquee {
  animation-play-state: paused;
}


@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 20px));
  }
}