.heating {
  position: relative;
  background-color: #1B1B1B;
  padding-top: 5rem;
  min-height: 100vh;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-75%);
    width: 100%;
    height: 35.6rem;
    background-image: url('./../img/logo-bg.svg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    display: none;

    @include mobile {
      display: block;
    }
  }

  @include mobile {
    background-color: #183475;
    margin-bottom: 12rem;
  }

  .container {
    height: 100%;
  }

  &__header {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include mobile {
      margin-bottom: 5.8rem;
    }
  }

  &__title {
    align-items: flex-end;
    padding-left: 0;
    max-width: 55rem;
    font-size: 3.2rem;

    &::before {
      content: "Котельная";
      left: 0;
    }

    margin-bottom: 6.6rem;

    @include mobile {
      // margin-bottom: 4rem;
      display: none;
    }
  }

  &__desc {
    margin: 0;
    max-width: 96rem;
    color: #FFF;
    font-family: "Roboto";
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include mobile {
      font-size: 2.6rem;
    }
  }

  &__video {
    position: absolute;
    z-index: 4;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;


    @include mobile {
      position: relative;

      height: 90vh;
    }

    video,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__slider {
    height: 100%;

    @include mobile {
      width: auto;
      overflow: visible;
    }
  }
}

.video-block {
  position: absolute;
  height: auto;
  width: auto;
  right: 25rem;

  top: 70%;
  will-change: transform;

  @include mobile {
    width: 100%;
    left: 3rem;
    right: unset;
    top: 0;
  }

  &__wrapper {
    flex-direction: column;


    @include mobile {
      flex-direction: row;
      gap: 7rem;
    }
  }

  &__slide {
    height: 75vh;
    width: 100%;
    // opacity: 0;
    max-width: 40rem;

    @include mobile {
      height: auto;
    }

    &>p {
      display: flex;
      align-items: center;
      margin: 0;
      width: 100%;
      color: #FFF;
      font-family: "Roboto";
      font-size: 2.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1;

      &>span {
        color: #183475;
        text-align: center;
        leading-trim: both;
        text-edge: cap;

        text-shadow: .8rem .6rem 1rem rgba(0, 0, 0, 0.35);
        font-family: "Oswald";
        font-size: 12.8rem;

        @include mobile {
          color: #fff;
        }
      }
    }
  }
}

.video-mob__btn {
  display: none;
  position: absolute;
  bottom: 6rem;
  left: 1.5rem;
  width: 25rem;
  height: 5rem;
  padding: 1.5rem;
  color: #FFF;
  font-family: "Roboto";
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #FFF;

  box-shadow: 8px 6px 10px 0px rgba(0, 0, 0, 0.35);

  @include mobile {
    display: block;
  }
}

.heating-video {
  clip-path: circle(15% at 25% 75%);
  will-change: clip-path;

  @include mobile {
    clip-path: unset;
  }
}