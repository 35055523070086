.header {
  position: absolute;
  z-index: 70;
  width: 100%;
  color: #FFF;
  font-family: Roboto;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include mobile {
    border-bottom: 0.1rem solid #fff;
  }

  &--secondary {
    position: relative;
    background: #FFF;
    box-shadow: 0.8rem 0.6rem 1rem 0px rgba(0, 0, 0, 0.35);

    color: #403D3A;

    .header__item {
      color: #403D3A;

    }

    .header__row {
      border-left: 0.1rem solid rgba(#403D3A, 0.5);

      border-bottom: 0.1rem solid rgba(#403D3A, 0.5);
    }

    .header__link {
      color: #403D3A;
    }

    .header__nav {
      .nav__item {
        @include hover {
          .nav__link {
            color: #fff !important;
          }
        }
      }

      .nav__link {
        color: #403D3A;


      }
    }

    .header__call {
      color: #403D3A;
      border-bottom: 1px solid #403D3A;

      svg {
        fill: #403D3A;
      }
    }

    .socials__link {
      svg {
        fill: #403D3A;
      }
    }

    .header__logo {

      svg {
        fill: #183475;
      }
    }

    .header__contact {
      color: #403D3A;
      border-right: 0.1rem solid rgba(#403D3A, 0.5);
      border-left: 0.1rem solid rgba(#403D3A, 0.5);

    }

    .burger {
      color: #403D3A;

      z-index: 999;
    }

    @include mobile {
      border-bottom: 0.1rem solid rgba(#403D3A, 0.5);
    }
  }


  &__logo {
    padding: 4.4rem;
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.50);
    display: flex;
    align-items: center;
    justify-content: center;

    &>a {
      display: block;
    }

    svg {
      max-width: 16.7rem;
      max-height: 6.3rem;
      width: 100%;
      fill: #fff;

      @include mobile {
        max-width: 9.8rem;
        height: 3.7rem;
      }
    }

    @include mobile {
      height: 100%;
      padding: 0;
      flex: 1;
      border-bottom: none;
      border-right: 0.1rem solid #fff;
    }
  }

  &__container {
    display: flex;
    width: 100%;
    justify-content: center;

    @include mobile {
      height: 6rem;
      align-items: center;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include mobile {
      display: none;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    border-left: 0.1rem solid rgba(255, 255, 255, 0.50);

    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.50);

  }

  &__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 16.8rem;

    @include mobile {
      flex-direction: column;
    }

  }

  &__item {
    padding: 1rem;
    color: #FFF;
    font-family: Roboto;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      padding: 0;
    }
  }

  &__phone {
    font-size: 2rem;

    @include hover {
      text-decoration: underline;
    }

    a {
      color: inherit;
    }
  }

  &__link {
    color: #FFF;
    font-family: Roboto;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include hover {
      text-decoration: underline;
    }
  }

  &__nav {
    flex: 1;
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
    padding-right: 3rem;
  }

  &__call {
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid #fff;
    color: #fff;

    svg {
      width: 2.5rem;
      height: 2.5rem;
      fill: #fff;
    }

  }

  &__contact {
    display: none;
    color: #FFF;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-right: 0.1rem solid #fff;
    height: 100%;

    button,
    a {
      color: inherit;
    }

    @include mobile {
      display: flex;
    }
  }

  &__burger {
    display: none;
    flex: 1;
    height: 100%;
    width: 100%;

    align-items: center;
    justify-content: center;

    @include mobile {
      display: flex;
    }
  }
}
