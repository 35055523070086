html {
  box-sizing: border-box;
  font-size: calc(100vw / 192);
  line-height: 1;
  -webkit-font-smoothing: antialised;

  @media (max-width: 768px) {
    font-size: calc(100vw / 43);
  }
}

html,
body {
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100vh;
  font-size: 16px;

}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  height: 100%;
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main {
  flex: 1;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 1.5rem;
  padding-bottom: 1.7rem;
  background-color: #294B9B;
  transition: background-color .2s ease-in, color .3s ease-in;
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
  width: 34rem;
  height: 6rem;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    background-color: #fff;
    transform: translateY(110%);
    transition: transform .3s ease-out;
  }

  @include hover {
    color: #294B9B;
    background-color: transparent;

    &::before {
      transform: translateY(0);
    }
  }

  @include mobile {
    width: 25rem;
    font-size: 1.8rem;
  }
}

.burger {
  width: 3rem;
  height: 2rem;
  position: relative;

  color: #fff;

  &__line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 0.2rem;
    background-color: currentColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background-color: currentColor;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.big-title {
  display: flex;
  align-items: center;
  height: 15rem;
  width: 100%;
  max-width: 130rem;

  margin: 0;
  margin-bottom: 3.4rem;


  padding-left: 12rem;

  position: relative;
  z-index: 2;

  color: #fff;
  font-family: "Oswald";
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  @include mobile {
    height: unset;
    font-size: 4rem;
    padding-left: unset;
    width: 100%;
    margin-bottom: 10rem;
  }



  &::before {
    content: "";
    position: absolute;
    white-space: nowrap;
    z-index: -1;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    color: rgba(255, 255, 255, 0.05);
    leading-trim: both;
    text-edge: cap;
    font-family: "Oswald";
    font-size: 18.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;

    @include mobile {
      font-size: 6.4rem;
      top: 25%;
      transform: translate(0, -75%);
      margin-left: -1.6rem;
    }
  }

  &--dark {
    color: #403D3A;

    &::before {
      color: rgba(24, 52, 117, 0.05);
    }
  }

}

input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
}


.mob-ord-2 {
  @include mobile {
    order: 2;
    margin-left: -1rem;
  }
}

.mob-ord-3 {
  @include mobile {
    order: 3;
  }
}

.mob-ord-4 {
  @include mobile {
    order: 4;
    margin-left: -1rem;
  }
}

._err {
  box-shadow: 0 0 .5rem red;
}

.just-validate-error-label {
  display: none;
}

.just-validate-error-field {
  box-shadow: 0 0 .5rem red !important;
}

.checkbox {
  display: none;

  &.just-validate-error-field {
    &+.fake-radio {
      box-shadow: 0 0 1.5rem red;
    }
  }

}


.fake-radio {
  display: block;
  position: relative;
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  box-shadow: .8rem .6rem 1rem 0rem rgba(0, 0, 0, 0.35);

  &::before {
    content: "";
    position: absolute;
    display: block;
    z-index: 2;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #2656c6;
  }
}


.checkbox:checked+.fake-radio {
  &::before {
    opacity: 1;
  }
}


.mob-contacts {
  position: fixed;
  z-index: 100;
  inset: 0;
  width: 100%;
  height: 100%;

  transform: translateY(-100%);
  transition: transform .3s ease-in;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    background-color: rgba(38, 86, 198, 0.336);
    opacity: 0;
    transition: opacity .3s ease-in;
  }

  &.active {
    &::before {
      opacity: 1;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
  }

  &>ul {
    padding: 5rem 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    height: 30%;
    background-color: #334161;
  }
}
