.hero {
  position: relative;

  &__wrapper {
    // min-height: 100vh;
    position: relative;
    background-image: url('./../img/hero-bg.webp');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(64, 61, 58, 0.40) 0%, rgba(64, 61, 58, 0.00) 56.93%);
    }

    @media (min-width: 1920px) {
      background-image: url('./../img/hero-bg-2x.webp');
    }

    @media (min-width: 2560px) {
      background-image: url('./../img/hero-bg-3x.webp');
    }

    @include mobile {
      background-image: url('./../img/hero-bg-mob.webp');
    }

  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 32rem;
    padding-bottom: 25.3rem;

    @include mobile {
      padding-top: 16.3rem;
      padding-bottom: 16.2rem;
    }
  }



  &__title {
    margin: 0;
    margin-bottom: 2rem;
    color: #FFF;
    text-shadow: 0.8rem 0.6rem 1rem rgba(0, 0, 0, 0.35);
    font-family: "Oswald";
    font-size: 6.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    @include mobile {
      font-size: 4rem;
    }
  }

  &__subtitle {
    margin: 0;
    margin-bottom: 5rem;
    color: #F4EEEA;
    font-family: "Roboto";
    font-size: 4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include mobile {
      font-size: 2.8rem;
      margin-bottom: 10rem;
    }
  }

  &__list {
    position: absolute;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);
    justify-self: flex-end;
    margin-top: auto;

    padding: 0 3rem;
    display: flex;
    justify-content: space-evenly;
    gap: 2.3rem;
    // gap: 2rem;

    @include mobile {
      display: grid;
      grid-template-columns: 1fr;
      position: relative;
      bottom: unset;
      margin-top: 3rem;
      padding: 0;
      gap: 1rem;
    }
  }

  &__item {
    width: 33.5rem;
    margin: 0 auto;
    height: 10rem;
    background: rgba(64, 61, 58, 0.65);
    box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
    padding: 2rem;
    padding-left: 7.5rem;
    padding-right: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: transform .3s ease-in;

    @include hover {
      animation: moving 2s linear infinite;
    }


    @include mobile {
      max-width: 28rem;
      height: 10rem;
      margin: 0;

      &:nth-child(even) {
        margin-left: auto;
      }

    }

    &>p {

      margin: 0;
      color: #FFF;
      font-family: "Roboto";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &::before {
      content: "";
      width: 6rem;
      height: 6rem;
      position: absolute;
      left: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('./../img/items/01.svg');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &--sup {

      &>p {
        max-width: 21rem;
      }

      &::before {
        background-image: url('./../img/items/01.svg');
      }
    }

    &--rel {

      &::before {
        background-image: url('./../img/items/02.svg');
      }
    }

    &--prot {

      &::before {
        background-image: url('./../img/items/03.svg');
      }
    }

    &--saf {


      &>p {
        max-width: 14.1rem;

        @include mobile {
          max-width: 11.8rem;
        }
      }

      &::before {
        width: 8rem;
        height: 8rem;
        background-image: url('./../img/items/04.svg');
      }
    }
  }
}

@keyframes moving {
  0% {
    transform: translateY(0rem);
  }

  25% {
    transform: translateY(-0.5rem);
  }

  50% {
    transform: translateY(0rem);
  }

  75% {
    transform: translateY(0.5rem);
  }

  100% {
    transform: translateY(0rem);
  }
}