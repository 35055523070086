.about {
  padding-bottom: 12rem;

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    @include mobile {
      grid-template-columns: 1fr;
      align-items: unset;
    }
  }

  &__title {
    &::before {
      content: "О нас";
      left: 1.5rem;
    }
  }

  &__info {
    position: relative;
    box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);

    @include mobile {}
  }

  &__images {
    height: 96rem;
    width: 100%;

    @include mobile {
      order: -1;
      margin-bottom: -30%;
      height: 44rem;
      width: 33rem;
      justify-self: flex-end;
    }
  }

  &__img {
    position: relative;
    height: 100%;
    width: 100%;

    img {
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.about-info {
  &__content {
    position: relative;
    z-index: 5;
    padding: 7.6rem 3.2rem;
    background-color: #183475;

    color: #FFF;
    font-family: "Roboto";
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include mobile {
      padding: 4rem 1.8rem;
    }

    &>p {
      margin: 0;
      color: #FFF;
      font-family: "Roboto";
      font-size: 2.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      @include mobile {
        font-size: 2rem;
      }
    }

  }

  &__img {
    position: absolute;
    z-index: 1;
    right: -42rem;
    top: -3.4rem;
    height: calc(100% + 7.4rem);
    width: 58rem;

    img {
      width: 100%;
      height: 100%;
    }

    @include mobile {
      height: 35.5rem;
      width: 26.6rem;
      right: unset;
      left: 0;
      top: -40%;
    }
  }
}