.menu {
  position: fixed;
  inset: 0;
  background-color: #334161;
  z-index: 80;
  overflow: scroll;
  transform: translateX(100%);
  display: none;
  transition: transform .3s ease-in;

  &__close {
    width: 20px;
    height: 30px;
    position: absolute;
    top: 2rem;
    right: 5.3rem;
    border: none;
    background-color: transparent;

    span {
      position: absolute;
      display: block;
      background-color: #fff;
      width: 100%;
      height: 3px;
      border-radius: 3px;

      transform: rotate(-45deg);

      &:nth-child(2) {
        transform-origin: center;
        transform: rotate(45deg);
      }
    }
  }

  &.active {
    transform: translateX(0);
  }

  @include mobile {
    display: block;
  }

  .header__item {
    width: 100%;
    border-bottom: 1px solid #fff;
  }

  .socials {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3rem;
    margin: 1rem;
  }

  .header__link {}

  .header__call {
    border-bottom: 1px solid #fff;

  }

  .nav__item {
    position: relative;
    border-bottom: 1px solid #fff;
  }

}