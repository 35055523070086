.accord {
  &__item {
    padding: 1rem 0;

    &:nth-child(even) {
      @media(min-width: 769px) {
        .accord__line {
          margin-left: -2rem;

          @include mobile {
            margin-left: unset;
          }
        }

        &.active {
          .accord__title::before {
            transform: rotate(90deg);
          }

        }

        .accord__title {
          order: -1;
          text-align: left !important;
          padding-left: 12rem;
          padding-right: 3rem !important;

          &::before {
            left: unset;
            right: 4.5rem;
            // transform: rotate(180deg) translate(-50%, 25%);
            background-image: url('./../img/triangle-right.svg');
          }


        }

        .accord__desc {
          grid-column: 6 / 7 span;
        }
      }
    }

    &:nth-child(odd) {
      @media (min-width: 769px) {
        .accord__line {
          margin-right: -2rem;
        }
      }
    }

    &.active {
      .accord__desc {
        margin-bottom: 6rem;
      }

      .accord__title::before {
        transform: rotate(-90deg);
      }

    }

  }


  &__row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;

    @include mobile {
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
    }

    &--desc {
      @include mobile {
        grid-template-columns: 1fr;
      }
    }
  }

  &__line {
    grid-column: 7 span;
    position: relative;

    @include mobile {
      grid-column: 1 span;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 2.4rem;
      transform: translateY(-50%);

      width: 100%;
      height: 0.1rem;
      background-color: #183475;

      @include mobile {
        top: 50%;
      }
    }
  }

  &__header {
    cursor: pointer;
    padding: 1rem 0;
  }

  &__title {
    grid-column: 5 span;
    position: relative;
    margin: 0;
    grid-column: 5 span;
    padding-left: unset;
    padding-right: 12rem;
    color: #403D3A;
    font-family: "Oswald";
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: right;

    @include mobile {
      grid-column: 2 span;
      padding-right: unset;
      font-size: 2rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 1.1rem;
      left: 5rem;
      transform: translate(-50%, 0);
      width: 2.5rem;
      height: 3rem;
      background-image: url('./../img/triangle.svg');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      transform-origin: center;
      transition: transform .1s ease-out;

      @include mobile {
        left: 2rem;
        transform: translateY(-50%);
        top: 50%;
        transform-origin: left;
      }
    }
  }

  &__desc {
    grid-column: 7 span;


    max-height: 0;
    overflow: hidden;
    will-change: max-height;
    transition: max-height .3s ease-out;

    &>p {
      margin: 0;
      color: #403D3A;
      font-family: "Roboto";
      font-size: 2.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
}