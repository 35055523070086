@charset "UTF-8";
:root {
  --font-family: "Open Sans", sans-serif;
  --content-width: 142rem;
  --container-offset: 1.6rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
}
@media (max-width: 768px) {
  :root {
    --content-width: 100%;
    --container-offset: 16px;
  }
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Oswald";
  src: url("../fonts/../fonts/Oswald-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
  font-size: 0.5208333333vw;
  line-height: 1;
  -webkit-font-smoothing: antialised;
}
@media (max-width: 768px) {
  html {
    font-size: 2.3255813953vw;
  }
}

html,
body {
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100vh;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main {
  flex: 1;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 1.5rem;
  padding-bottom: 1.7rem;
  background-color: #294B9B;
  transition: background-color 0.2s ease-in, color 0.3s ease-in;
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
  width: 34rem;
  height: 6rem;
}
.btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  background-color: #fff;
  transform: translateY(110%);
  transition: transform 0.3s ease-out;
}
@media (any-hover: hover) {
  .btn:hover {
    color: #294B9B;
    background-color: transparent;
  }
  .btn:hover::before {
    transform: translateY(0);
  }
}
@media (max-width: 768px) {
  .btn {
    width: 25rem;
    font-size: 1.8rem;
  }
}

.burger {
  width: 3rem;
  height: 2rem;
  position: relative;
  color: #fff;
}
.burger__line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 0.2rem;
  background-color: currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
}
.burger::before, .burger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background-color: currentColor;
}
.burger::before {
  top: 0;
}
.burger::after {
  bottom: 0;
}

.big-title {
  display: flex;
  align-items: center;
  height: 15rem;
  width: 100%;
  max-width: 130rem;
  margin: 0;
  margin-bottom: 3.4rem;
  padding-left: 12rem;
  position: relative;
  z-index: 2;
  color: #fff;
  font-family: "Oswald";
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .big-title {
    height: unset;
    font-size: 4rem;
    padding-left: unset;
    width: 100%;
    margin-bottom: 10rem;
  }
}
.big-title::before {
  content: "";
  position: absolute;
  white-space: nowrap;
  z-index: -1;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  color: rgba(255, 255, 255, 0.05);
  leading-trim: both;
  text-edge: cap;
  font-family: "Oswald";
  font-size: 18.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .big-title::before {
    font-size: 6.4rem;
    top: 25%;
    transform: translate(0, -75%);
    margin-left: -1.6rem;
  }
}
.big-title--dark {
  color: #403D3A;
}
.big-title--dark::before {
  color: rgba(24, 52, 117, 0.05);
}

input[type=checkbox] {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 768px) {
  .mob-ord-2 {
    order: 2;
    margin-left: -1rem;
  }
}

@media (max-width: 768px) {
  .mob-ord-3 {
    order: 3;
  }
}

@media (max-width: 768px) {
  .mob-ord-4 {
    order: 4;
    margin-left: -1rem;
  }
}

._err {
  box-shadow: 0 0 0.5rem red;
}

.just-validate-error-label {
  display: none;
}

.just-validate-error-field {
  box-shadow: 0 0 0.5rem red !important;
}

.checkbox {
  display: none;
}
.checkbox.just-validate-error-field + .fake-radio {
  box-shadow: 0 0 1.5rem red;
}

.fake-radio {
  display: block;
  position: relative;
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
}
.fake-radio::before {
  content: "";
  position: absolute;
  display: block;
  z-index: 2;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #2656c6;
}

.checkbox:checked + .fake-radio::before {
  opacity: 1;
}

.mob-contacts {
  position: fixed;
  z-index: 100;
  inset: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in;
}
.mob-contacts::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  background-color: rgba(38, 86, 198, 0.336);
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
.mob-contacts.active::before {
  opacity: 1;
}
.mob-contacts__row {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}
.mob-contacts > ul {
  padding: 5rem 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  height: 30%;
  background-color: #334161;
}

.header {
  position: absolute;
  z-index: 70;
  width: 100%;
  color: #FFF;
  font-family: Roboto;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .header {
    border-bottom: 0.1rem solid #fff;
  }
}
.header--secondary {
  position: relative;
  background: #FFF;
  box-shadow: 0.8rem 0.6rem 1rem 0px rgba(0, 0, 0, 0.35);
  color: #403D3A;
}
.header--secondary .header__item {
  color: #403D3A;
}
.header--secondary .header__row {
  border-left: 0.1rem solid rgba(64, 61, 58, 0.5);
  border-bottom: 0.1rem solid rgba(64, 61, 58, 0.5);
}
.header--secondary .header__link {
  color: #403D3A;
}
@media (any-hover: hover) {
  .header--secondary .header__nav .nav__item:hover .nav__link {
    color: #fff !important;
  }
}
.header--secondary .header__nav .nav__link {
  color: #403D3A;
}
.header--secondary .header__call {
  color: #403D3A;
  border-bottom: 1px solid #403D3A;
}
.header--secondary .header__call svg {
  fill: #403D3A;
}
.header--secondary .socials__link svg {
  fill: #403D3A;
}
.header--secondary .header__logo svg {
  fill: #183475;
}
.header--secondary .header__contact {
  color: #403D3A;
  border-right: 0.1rem solid rgba(64, 61, 58, 0.5);
  border-left: 0.1rem solid rgba(64, 61, 58, 0.5);
}
.header--secondary .burger {
  color: #403D3A;
  z-index: 999;
}
@media (max-width: 768px) {
  .header--secondary {
    border-bottom: 0.1rem solid rgba(64, 61, 58, 0.5);
  }
}
.header__logo {
  padding: 4.4rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__logo > a {
  display: block;
}
.header__logo svg {
  max-width: 16.7rem;
  max-height: 6.3rem;
  width: 100%;
  fill: #fff;
}
@media (max-width: 768px) {
  .header__logo svg {
    max-width: 9.8rem;
    height: 3.7rem;
  }
}
@media (max-width: 768px) {
  .header__logo {
    height: 100%;
    padding: 0;
    flex: 1;
    border-bottom: none;
    border-right: 0.1rem solid #fff;
  }
}
.header__container {
  display: flex;
  width: 100%;
  justify-content: center;
}
@media (max-width: 768px) {
  .header__container {
    height: 6rem;
    align-items: center;
  }
}
.header__col {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media (max-width: 768px) {
  .header__col {
    display: none;
  }
}
.header__row {
  display: flex;
  width: 100%;
  border-left: 0.1rem solid rgba(255, 255, 255, 0.5);
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.5);
}
.header__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16.8rem;
}
@media (max-width: 768px) {
  .header__list {
    flex-direction: column;
  }
}
.header__item {
  padding: 1rem;
  color: #FFF;
  font-family: Roboto;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__item:last-child {
  padding: 0;
}
.header__phone {
  font-size: 2rem;
}
@media (any-hover: hover) {
  .header__phone:hover {
    text-decoration: underline;
  }
}
.header__phone a {
  color: inherit;
}
.header__link {
  color: #FFF;
  font-family: Roboto;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (any-hover: hover) {
  .header__link:hover {
    text-decoration: underline;
  }
}
.header__nav {
  flex: 1;
}
.header__socials {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6rem;
  padding-right: 3rem;
}
.header__call {
  display: inline-block;
  margin-right: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid #fff;
  color: #fff;
}
.header__call svg {
  width: 2.5rem;
  height: 2.5rem;
  fill: #fff;
}
.header__contact {
  display: none;
  color: #FFF;
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-right: 0.1rem solid #fff;
  height: 100%;
}
.header__contact button,
.header__contact a {
  color: inherit;
}
@media (max-width: 768px) {
  .header__contact {
    display: flex;
  }
}
.header__burger {
  display: none;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .header__burger {
    display: flex;
  }
}

.hero {
  position: relative;
}
.hero__wrapper {
  position: relative;
  background-image: url("./../img/hero-bg.webp");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hero__wrapper::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, rgba(64, 61, 58, 0.4) 0%, rgba(64, 61, 58, 0) 56.93%);
}
@media (min-width: 1920px) {
  .hero__wrapper {
    background-image: url("./../img/hero-bg-2x.webp");
  }
}
@media (min-width: 2560px) {
  .hero__wrapper {
    background-image: url("./../img/hero-bg-3x.webp");
  }
}
@media (max-width: 768px) {
  .hero__wrapper {
    background-image: url("./../img/hero-bg-mob.webp");
  }
}
.hero__container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 32rem;
  padding-bottom: 25.3rem;
}
@media (max-width: 768px) {
  .hero__container {
    padding-top: 16.3rem;
    padding-bottom: 16.2rem;
  }
}
.hero__title {
  margin: 0;
  margin-bottom: 2rem;
  color: #FFF;
  text-shadow: 0.8rem 0.6rem 1rem rgba(0, 0, 0, 0.35);
  font-family: "Oswald";
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .hero__title {
    font-size: 4rem;
  }
}
.hero__subtitle {
  margin: 0;
  margin-bottom: 5rem;
  color: #F4EEEA;
  font-family: "Roboto";
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .hero__subtitle {
    font-size: 2.8rem;
    margin-bottom: 10rem;
  }
}
.hero__list {
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  justify-self: flex-end;
  margin-top: auto;
  padding: 0 3rem;
  display: flex;
  justify-content: space-evenly;
  gap: 2.3rem;
}
@media (max-width: 768px) {
  .hero__list {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    bottom: unset;
    margin-top: 3rem;
    padding: 0;
    gap: 1rem;
  }
}
.hero__item {
  width: 33.5rem;
  margin: 0 auto;
  height: 10rem;
  background: rgba(64, 61, 58, 0.65);
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
  padding: 2rem;
  padding-left: 7.5rem;
  padding-right: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.3s ease-in;
}
@media (any-hover: hover) {
  .hero__item:hover {
    animation: moving 2s linear infinite;
  }
}
@media (max-width: 768px) {
  .hero__item {
    max-width: 28rem;
    height: 10rem;
    margin: 0;
  }
  .hero__item:nth-child(even) {
    margin-left: auto;
  }
}
.hero__item > p {
  margin: 0;
  color: #FFF;
  font-family: "Roboto";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.hero__item::before {
  content: "";
  width: 6rem;
  height: 6rem;
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("./../img/items/01.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero__item--sup > p {
  max-width: 21rem;
}
.hero__item--sup::before {
  background-image: url("./../img/items/01.svg");
}
.hero__item--rel::before {
  background-image: url("./../img/items/02.svg");
}
.hero__item--prot::before {
  background-image: url("./../img/items/03.svg");
}
.hero__item--saf > p {
  max-width: 14.1rem;
}
@media (max-width: 768px) {
  .hero__item--saf > p {
    max-width: 11.8rem;
  }
}
.hero__item--saf::before {
  width: 8rem;
  height: 8rem;
  background-image: url("./../img/items/04.svg");
}

@keyframes moving {
  0% {
    transform: translateY(0rem);
  }
  25% {
    transform: translateY(-0.5rem);
  }
  50% {
    transform: translateY(0rem);
  }
  75% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
.nav__list {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .nav__list {
    flex-direction: column;
  }
}
.nav__item {
  position: relative;
  flex: 1;
  text-align: center;
  transition: background-color 0.3s ease-in;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (any-hover: hover) {
  .nav__item:hover {
    background-color: #334161;
  }
}
.nav__link {
  display: inline-block;
  padding: 1rem;
  padding-top: 0.8rem;
  color: #FFF;
  font-family: Roboto;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: color 0.3s ease-in;
}
.nav__link::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  inset: 0;
  width: 100%;
  height: 100%;
}

.socials__link {
  display: block;
  width: 4rem;
  height: 4rem;
}
.socials__link svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

.benefits__slider-wrapper {
  width: 100%;
}
.benefits__slider {
  display: none;
  height: 25.5rem;
}
.benefits__slider .swiper-slide {
  width: auto;
}
@media (max-width: 768px) {
  .benefits__slider {
    display: block;
  }
}
.benefits__slider-control {
  display: none;
  padding-top: 2.5rem;
  padding-bottom: 6.1rem;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
}
@media (max-width: 768px) {
  .benefits__slider-control {
    display: flex;
  }
}
.benefits__slider-control .benefits-button-prev,
.benefits__slider-control .benefits-button-next {
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  transform: rotate(45deg);
}
.benefits__slider-control .benefits-button-prev {
  border-left: 0.2rem solid #183475;
  border-bottom: 0.2rem solid #183475;
}
.benefits__slider-control .benefits-button-next {
  border-top: 0.2rem solid #183475;
  border-right: 0.2rem solid #183475;
}
.benefits__slider-control .benefits-pagination {
  width: unset;
  color: #183475;
  font-family: "Oswald";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.benefits__row {
  height: 100%;
  padding-left: 37rem;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  transition: linear;
  will-change: transform;
  counter-reset: counter 0;
}
@media (max-width: 768px) {
  .benefits__row {
    padding-left: unset;
    flex-wrap: wrap;
  }
}
.benefits__col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.benefits__items {
  display: flex;
  height: 100%;
}
@media (max-width: 768px) {
  .benefits__items {
    display: none;
  }
}
.benefits__header {
  height: 100%;
  position: relative;
  max-width: 80rem;
  flex-shrink: 0;
  margin-right: 14rem;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .benefits__header {
    text-align: center;
    flex-shrink: 1;
    padding: 0 1.5rem;
    margin-right: 0;
    margin-bottom: 8rem;
  }
}
.benefits__title {
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 10rem;
  margin-bottom: 5rem;
  height: 15rem;
  width: 100%;
  position: relative;
  z-index: 2;
  color: #403D3A;
  font-family: "Oswald";
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
  text-align: left;
}
@media (max-width: 768px) {
  .benefits__title {
    height: unset;
    margin-bottom: 4rem;
    display: block;
  }
}
.benefits__title::after {
  content: "Преимущества";
  position: absolute;
  z-index: -1;
  left: 0%;
  top: 50%;
  transform: translate(-12rem, -50%);
  color: rgba(24, 52, 117, 0.05);
  leading-trim: both;
  text-edge: cap;
  font-family: "Oswald";
  font-size: 18.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .benefits__title::after {
    display: block;
    font-size: 6.4rem;
    left: 1rem;
    top: -2.6rem;
    margin-left: -1.5rem;
    transform: unset;
  }
}
.benefits__heater {
  position: absolute;
  bottom: -1rem;
  left: 37rem;
  width: 88.5rem;
}
.benefits__heater img {
  height: 100%;
}
@media (max-width: 768px) {
  .benefits__heater {
    position: relative;
    left: unset;
    bottom: unset;
    order: 0;
    padding: 0 1.5rem;
  }
}
@media (max-width: 768px) {
  .benefits__btn {
    align-self: center;
  }
}
.benefits__desc {
  margin: 0;
  margin-bottom: 8rem;
  color: #403D3A;
  font-family: "Roboto";
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
@media (max-width: 768px) {
  .benefits__desc {
    margin-bottom: 5rem;
    font-size: 2rem;
  }
}

.benefit {
  counter-increment: counter;
  width: 62.2rem;
  height: 54rem;
  padding: 5.8rem 3.3rem;
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  transition: background-color 0.3s ease;
}
@media (max-width: 768px) {
  .benefit {
    padding: 0;
    width: 100%;
    height: 100%;
  }
}
@media (any-hover: hover) {
  .benefit:hover {
    background-color: #183475;
  }
  .benefit:hover svg {
    fill: none;
    stroke: #fff;
  }
  .benefit:hover p {
    color: #fff;
  }
  .benefit:hover::before {
    color: rgba(255, 255, 255, 0.5);
  }
}
.benefit::before {
  content: counter(counter);
  position: absolute;
  z-index: 1;
  top: 3rem;
  left: 3rem;
  color: rgba(24, 52, 117, 0.5);
  leading-trim: both;
  text-edge: cap;
  font-family: "Oswald";
  font-size: 18.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .benefit::before {
    left: unset;
    top: 0;
    right: 0;
  }
}
.benefit svg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20rem;
  height: 20rem;
  fill: #fff;
  transition: fill 0.3s ease-in;
}
.benefit > p {
  margin: 0 auto;
  font-family: "Roboto";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #403D3A;
  max-width: 39.4rem;
}
@media (max-width: 768px) {
  .benefit > p {
    padding: 3.853rem;
    padding-left: 3.4rem;
    padding-right: 2.8rem;
  }
}
.benefit--blue {
  background: #183475;
}
@media (any-hover: hover) {
  .benefit--blue:hover {
    background-color: #fff;
  }
  .benefit--blue:hover p {
    color: #1B1B1B;
  }
  .benefit--blue:hover svg {
    fill: #1b1b1b;
  }
  .benefit--blue:hover::before {
    color: rgba(0, 0, 0, 0.5);
  }
}
.benefit--blue::before {
  color: rgba(255, 255, 255, 0.1);
}
.benefit--blue > p {
  color: #FFF;
}
.benefit--dark {
  background: #1B1B1B;
}
@media (any-hover: hover) {
  .benefit--dark:hover {
    background-color: #fff;
  }
  .benefit--dark:hover p {
    color: #1B1B1B;
  }
  .benefit--dark:hover svg {
    fill: #1b1b1b;
  }
  .benefit--dark:hover::before {
    color: rgba(0, 0, 0, 0.5);
  }
}
.benefit--dark > p {
  max-width: 37rem;
}
.benefit--dark::before {
  color: rgba(255, 255, 255, 0.1);
}
.benefit--dark > p {
  color: #FFF;
}
.benefit--1::after {
  background-image: url("./../img/benefits/01.svg");
}
.benefit--2::after {
  background-image: url("./../img/benefits/02.svg");
}
.benefit--3 svg {
  stroke: #183475;
}
.benefit--3::after {
  background-image: url("./../img/benefits/03.svg");
}
.benefit--4::after {
  background-image: url("./../img/benefits/04.svg");
}
.benefit--5::after {
  background-image: url("./../img/benefits/05.svg");
}
.benefit--6::after {
  background-image: url("./../img/benefits/06.svg");
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2rem);
  }
  100% {
    transform: translateY(0);
  }
}
.reliability {
  padding-top: 17rem;
  padding-bottom: 16rem;
  background-color: #183475;
  position: relative;
}
.reliability::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 77.9rem;
  height: 64.6rem;
  background-image: url("./../img/logo-bg.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .reliability::before {
    top: 10.6rem;
    width: 100%;
    height: 35.6rem;
  }
}
@media (max-width: 768px) {
  .reliability {
    padding-top: 14.5rem;
  }
}
.reliability__advs {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;
  margin-bottom: 7.5rem;
}
@media (max-width: 768px) {
  .reliability__advs {
    grid-template-columns: 1fr;
    gap: 12rem;
    margin-bottom: 11rem;
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
}
.reliability__title {
  position: relative;
  z-index: 5;
  margin: 0;
  margin-bottom: 20rem;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  text-align: center;
  text-shadow: 0.8rem 0.6rem 1rem rgba(0, 0, 0, 0.35);
  font-family: "Oswald";
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .reliability__title {
    font-size: 6.4rem;
    margin-bottom: 25.5rem;
    white-space: unset;
  }
}
.reliability__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20rem;
}
@media (max-width: 768px) {
  .reliability__list {
    flex-wrap: wrap;
    margin-bottom: 5rem;
  }
}

.rel-item {
  text-align: left;
  position: relative;
}
@media (max-width: 768px) {
  .rel-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1.6rem;
    border-bottom: 0.1rem solid #fff;
  }
  .rel-item:not(:last-child) {
    margin-bottom: 5rem;
  }
}
.rel-item__title {
  margin: 0;
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: "Oswald";
  font-size: 10rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}
.rel-item__title > span {
  text-transform: lowercase;
  font-size: 4rem;
}
@media (max-width: 768px) {
  .rel-item__title > span {
    font-size: 2rem;
  }
}
.rel-item__title::after {
  content: "";
  display: block;
  margin: 1.3rem 0;
  width: 25rem;
  height: 0.1rem;
  background-color: #fff;
}
@media (max-width: 768px) {
  .rel-item__title::after {
    display: none;
  }
}
@media (max-width: 768px) {
  .rel-item__title {
    flex-grow: 1;
    font-size: 4rem;
  }
}
.rel-item__text {
  margin: 0;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .rel-item__text {
    max-width: 16rem;
    text-align: right;
    font-size: 1.3rem;
  }
}

.rel-advs__list {
  grid-column: 7 span;
  margin-right: -2rem;
}
@media (max-width: 768px) {
  .rel-advs__list {
    grid-column: 1 span;
  }
}
.rel-advs__item {
  overflow: hidden;
  padding-left: 25rem;
}
.rel-advs__item:not(:last-child) {
  margin-bottom: 3rem;
}
@media (max-width: 768px) {
  .rel-advs__item {
    padding-left: 12.5rem;
  }
}
.rel-advs__item:nth-child(even) {
  padding-left: 15rem;
}
@media (max-width: 768px) {
  .rel-advs__item:nth-child(even) {
    padding-left: 7rem;
  }
  .rel-advs__item:nth-child(even) > .rel-advs__desc {
    max-width: unset;
  }
}
.rel-advs__title {
  display: flex;
  position: relative;
  margin: 0;
  margin-bottom: 1rem;
  color: #FFF;
  font-family: "Oswald";
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .rel-advs__title {
    font-size: 2.4rem;
  }
}
.rel-advs__title::before {
  content: "";
  flex-grow: 1;
  display: block;
  position: absolute;
  left: -100%;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 0.1rem;
  background-color: #fff;
}
@media (max-width: 768px) {
  .rel-advs__title::before {
    top: 1.8rem;
    transform: unset;
  }
}
.rel-advs__desc {
  margin: 0;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .rel-advs__desc {
    max-width: 28rem;
  }
}
.rel-advs__images {
  grid-column: 5 span;
  margin-left: 7rem;
  height: 100%;
  width: 100%;
  position: relative;
  height: 60.5rem;
  max-width: 45.3rem;
}
@media (max-width: 768px) {
  .rel-advs__images {
    grid-column: 1 span;
    order: -1;
    height: 37.3rem;
    max-width: 27.9rem;
    margin-left: unset;
    margin: 0 auto;
  }
}
.rel-advs__img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rel-advs__img:nth-child(1) {
  left: 0;
  top: 0;
  z-index: 2;
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
}
.rel-advs__img:nth-child(2) {
  top: 6rem;
  right: -6rem;
  z-index: 1;
}
@media (max-width: 768px) {
  .rel-advs__img:nth-child(2) {
    top: 3.7rem;
    right: -3.7rem;
  }
}

.rel-first-det,
.rel-second-det,
.rel-third-det {
  width: 16.1rem;
  height: 16.1rem;
  position: absolute;
  z-index: -1;
  transition: 0.6s linear;
}

.rel-first-det {
  left: 5%;
  bottom: -65%;
}
@media (max-width: 768px) {
  .rel-first-det {
    left: -15%;
    bottom: -70%;
  }
}

.rel-second-det {
  left: 60%;
  top: 0%;
}
@media (max-width: 768px) {
  .rel-second-det {
    left: 30%;
    top: -35%;
  }
}

.rel-third-det {
  right: 8%;
  bottom: -70%;
  width: 9.1rem;
  height: 11.8rem;
}
@media (max-width: 768px) {
  .rel-third-det {
    bottom: -100%;
    right: -10%;
  }
}

.partners__title {
  display: flex;
  align-items: center;
  height: 15rem;
  width: 100%;
  max-width: 130rem;
  margin: 0;
  margin-bottom: 3.4rem;
  padding-left: 12rem;
  position: relative;
  z-index: 2;
  color: #fff;
  font-family: "Oswald";
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .partners__title {
    height: unset;
    font-size: 4rem;
    padding-left: unset;
    width: 100%;
    margin-bottom: 10rem;
  }
}
.partners__title::before {
  content: "Наши партнеры";
  position: absolute;
  white-space: nowrap;
  z-index: -1;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  color: rgba(255, 255, 255, 0.05);
  leading-trim: both;
  text-edge: cap;
  font-family: "Oswald";
  font-size: 18.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .partners__title::before {
    font-size: 6.4rem;
    top: 25%;
    transform: translate(0, -75%);
    margin-left: -1.6rem;
  }
}
.partners__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 5rem;
  width: 50rem;
  height: 11rem;
}
.partners__item::after {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateY(110%);
  transition: transform 0.3s ease-out;
}
.partners__item svg {
  height: 5rem;
  fill: #fff;
  transition: fill 0.3s ease-out, stroke 0.3s ease-out;
}
.partners__item--auto svg {
  fill: none;
  stroke: #fff;
}
.partners__item--auto:hover svg {
  fill: none !important;
  stroke: #183475;
}
.partners__item--rus {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.partners__item--rus svg {
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .partners__item {
    padding-right: 0;
    padding-left: 0;
    width: 31.7rem;
  }
}
.partners__item:hover::after {
  transform: translateY(0);
}
.partners__item:hover svg {
  fill: #183475;
}

.items-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  border-top: 1px solid #fff;
}
@media (max-width: 768px) {
  .items-wrap {
    border-bottom: 1px solid #fff;
  }
}
.items-wrap:last-child {
  border-bottom: 1px solid #fff;
}
@media (max-width: 768px) {
  .items-wrap:last-child {
    display: none;
  }
}

.items {
  flex-shrink: 0;
  display: flex;
  min-width: 100%;
}

.marquee {
  animation: scroll 20s linear infinite;
}

.reverce {
  animation-direction: reverse;
}

.items-wrap:hover .marquee {
  animation-play-state: paused;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}
.rent {
  padding-top: 12rem;
  padding-bottom: 17rem;
}
@media (max-width: 768px) {
  .rent {
    padding-top: 8.4rem;
    padding-bottom: 8.8rem;
  }
}
@media (max-width: 768px) {
  .rent__image {
    display: none;
  }
}
.rent__image img {
  height: 100%;
  width: 100%;
}
.rent__title::before {
  content: "Аренда";
}
@media (max-width: 768px) {
  .rent__title {
    width: 100vw;
    max-width: unset;
    margin-bottom: 5rem;
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
}
.rent__items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 768px) {
  .rent__items {
    grid-template-columns: minmax(0, 1fr);
    gap: 2rem 0;
  }
}

.rent-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 12.3rem;
  padding-bottom: 5rem;
  background-color: #FFF;
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
  transition: background-color 0.3s ease-in;
}
@media (max-width: 768px) {
  .rent-item {
    height: 68rem;
  }
}
@media (max-width: 768px) {
  .rent-item {
    padding: 3rem 1.9rem;
    padding-bottom: 3.4rem;
  }
}
.rent-item__title {
  margin: 0;
  margin-bottom: 4rem;
  color: #403D3A;
  text-align: center;
  font-family: "Oswald";
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.096rem;
  text-transform: uppercase;
  transition: color 0.3s ease-in;
}
@media (max-width: 768px) {
  .rent-item__title {
    margin-bottom: 6rem;
  }
}
.rent-item__list {
  margin-bottom: 2.6rem;
}
.rent-item__item {
  position: relative;
  padding-left: 4rem;
  color: #403D3A;
  font-family: "Roboto";
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: color 0.3s ease-in;
}
.rent-item__item:not(:last-child) {
  margin-bottom: 2rem;
}
.rent-item__item > span {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
}
.rent-item__item > span svg {
  width: 100%;
  height: 100%;
  fill: #183475;
  transition: fill 0.3s ease-in;
}
.rent-item__btn {
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 37.5rem;
  height: 7rem;
  margin-top: auto;
  padding: 1rem 2rem;
  color: #FFF;
  background-color: #294B9B;
  text-align: center;
  font-family: "Roboto";
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.rent-item__btn::before {
  content: "";
  position: absolute;
  z-index: 5;
  inset: 0;
  background-color: unset;
  transform: unset;
}
@media (max-width: 768px) {
  .rent-item__btn {
    width: 100%;
  }
}
.rent-item:hover {
  background: #183475;
}
.rent-item:hover .rent-item__item {
  color: #fff;
}
.rent-item:hover .rent-item__item svg {
  fill: #fff;
}
.rent-item:hover .rent-item__title {
  color: #fff;
}
.rent-item:hover .rent-item__btn {
  background-color: #294B9B;
  color: #fff !important;
}

.compos {
  padding-bottom: 13.7rem;
  background-color: #183475;
}
@media (max-width: 768px) {
  .compos {
    padding-top: 5.5rem;
    padding-bottom: 12rem;
  }
}
.compos__slider {
  display: none;
}
@media (max-width: 768px) {
  .compos__slider {
    display: block;
  }
}
.compos__control {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 0.7rem;
}
.compos__text {
  margin: 0;
  color: #FFF;
  font-family: "Roboto";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.compos__text--main {
  max-width: 25.6rem;
  position: absolute;
  z-index: 8;
  right: 10rem;
  bottom: 3rem;
}
@media (max-width: 768px) {
  .compos__text--main {
    display: none;
  }
}
.compos__row {
  position: relative;
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}
.compos__title {
  padding-left: 0;
}
.compos__title::before {
  content: "котельная";
}
@media (max-width: 768px) {
  .compos__title {
    margin-bottom: 4rem;
  }
}
.compos__desc {
  max-width: 94.5rem;
  margin: 0;
  margin-top: -3rem;
  margin-bottom: 15rem;
  padding-left: 12.2rem;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .compos__desc {
    padding-left: 0;
    margin-bottom: 10rem;
    margin-top: -1rem;
  }
}
.compos__content {
  position: relative;
}
@media (max-width: 768px) {
  .compos__content {
    display: none;
  }
}
.compos__image {
  position: absolute;
  top: 50%;
  right: 0;
  height: 96%;
  transform: translateY(-50%);
  max-width: 127rem;
}
.compos__image img {
  height: 100%;
}
.compos__item {
  max-width: 30rem;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.compos__item:not(:last-child) {
  margin-bottom: 5rem;
}

.compos-button-prev,
.compos-button-next {
  position: relative;
  width: 2rem;
  height: 2rem;
  transform: rotate(45deg);
}

.compos-button-prev {
  border-left: 0.2rem solid #fff;
  border-bottom: 0.2rem solid #fff;
}

.compos-button-next {
  border-top: 0.2rem solid #fff;
  border-right: 0.2rem solid #fff;
}

.compos-pagination {
  width: unset;
  color: #fff;
  font-family: "Oswald";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.discount {
  background-color: #183475;
  padding: 8rem 0;
  position: relative;
  z-index: 15;
}
@media (max-width: 768px) {
  .discount {
    padding-top: 0;
  }
}
.discount::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 7.9rem;
  transform: translate(-50%, 0);
  height: 64.6rem;
  width: 77.9rem;
  background-image: url("./../img/logo-bg.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .discount::after {
    display: none;
  }
}
.discount__header {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .discount__header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 12.2rem;
  }
}
.discount__container {
  max-width: 117rem;
  margin: 0 auto;
}
.discount__title {
  margin-left: -12rem;
  height: unset;
  margin-bottom: 2rem;
}
.discount__title::before {
  content: "скидка";
}
@media (max-width: 768px) {
  .discount__title {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    margin-bottom: 5rem;
    max-width: unset;
  }
}
.discount__desc {
  align-self: flex-end;
  position: relative;
  margin: 0;
  color: #FFF;
  max-width: 50.2rem;
  text-shadow: 0.8rem 0.6rem 1rem rgba(0, 0, 0, 0.35);
  font-family: "Oswald";
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .discount__desc {
    font-size: 2.8rem;
    max-width: 28.1rem;
    align-self: unset;
  }
}
.discount__desc span {
  font-size: 12.8rem;
  line-height: 1.15;
}
@media (max-width: 768px) {
  .discount__desc span {
    position: absolute;
    top: 35%;
    right: 0;
    font-size: 9.6rem;
    transform: translateX(65%);
  }
}

.disc-form {
  position: relative;
  z-index: 5;
}
@media (max-width: 768px) {
  .disc-form {
    padding: 0 1.5rem;
  }
}
.disc-form__item:not(:last-child) {
  margin-bottom: 5rem;
}
@media (max-width: 768px) {
  .disc-form__item:not(:last-child) {
    margin-bottom: 4rem;
  }
}
.disc-form__question {
  margin: 0;
  margin-bottom: 3rem;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .disc-form__question {
    margin-bottom: 2rem;
  }
}
.disc-form__label {
  position: relative;
  display: flex;
  align-items: center;
  gap: 3rem;
  cursor: pointer;
  user-select: none;
}
@media (max-width: 768px) {
  .disc-form__label {
    width: 100%;
  }
}
.disc-form__label input[type=checkbox] {
  cursor: pointer;
  background: #FFF;
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
}
.disc-form__label span {
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.disc-form__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.disc-form__row--unspace {
  justify-content: unset;
  gap: 10rem;
}
@media (max-width: 768px) {
  .disc-form__row--unspace .disc-form__label {
    width: auto;
  }
}
@media (max-width: 768px) {
  .disc-form__row--unspace {
    gap: unset;
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .disc-form__row {
    flex-wrap: wrap;
    gap: 2rem;
  }
}
.disc-form__input {
  padding: 1rem;
  background-color: unset;
  border: none;
  border-bottom: 0.2rem solid #FFF;
  width: 100%;
  color: #fff;
  font-family: "Roboto";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.disc-form__input--address {
  width: auto;
}
.disc-form__input:focus {
  outline: none;
}
.disc-form__input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.disc-form__input--address {
  flex: 1;
}
.disc-form__btn {
  cursor: pointer;
  text-align: center;
}
@media (max-width: 768px) {
  .disc-form__btn {
    margin: 0 auto;
  }
}

.heating {
  position: relative;
  background-color: #1B1B1B;
  padding-top: 5rem;
  min-height: 100vh;
}
.heating::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-75%);
  width: 100%;
  height: 35.6rem;
  background-image: url("./../img/logo-bg.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: none;
}
@media (max-width: 768px) {
  .heating::before {
    display: block;
  }
}
@media (max-width: 768px) {
  .heating {
    background-color: #183475;
    margin-bottom: 12rem;
  }
}
.heating .container {
  height: 100%;
}
.heating__header {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 768px) {
  .heating__header {
    margin-bottom: 5.8rem;
  }
}
.heating__title {
  align-items: flex-end;
  padding-left: 0;
  max-width: 55rem;
  font-size: 3.2rem;
  margin-bottom: 6.6rem;
}
.heating__title::before {
  content: "Котельная";
  left: 0;
}
@media (max-width: 768px) {
  .heating__title {
    display: none;
  }
}
.heating__desc {
  margin: 0;
  max-width: 96rem;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .heating__desc {
    font-size: 2.6rem;
  }
}
.heating__video {
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
}
@media (max-width: 768px) {
  .heating__video {
    position: relative;
    height: 90vh;
  }
}
.heating__video video,
.heating__video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.heating__slider {
  height: 100%;
}
@media (max-width: 768px) {
  .heating__slider {
    width: auto;
    overflow: visible;
  }
}

.video-block {
  position: absolute;
  height: auto;
  width: auto;
  right: 25rem;
  top: 70%;
  will-change: transform;
}
@media (max-width: 768px) {
  .video-block {
    width: 100%;
    left: 3rem;
    right: unset;
    top: 0;
  }
}
.video-block__wrapper {
  flex-direction: column;
}
@media (max-width: 768px) {
  .video-block__wrapper {
    flex-direction: row;
    gap: 7rem;
  }
}
.video-block__slide {
  height: 75vh;
  width: 100%;
  max-width: 40rem;
}
@media (max-width: 768px) {
  .video-block__slide {
    height: auto;
  }
}
.video-block__slide > p {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
.video-block__slide > p > span {
  color: #183475;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  text-shadow: 0.8rem 0.6rem 1rem rgba(0, 0, 0, 0.35);
  font-family: "Oswald";
  font-size: 12.8rem;
}
@media (max-width: 768px) {
  .video-block__slide > p > span {
    color: #fff;
  }
}

.video-mob__btn {
  display: none;
  position: absolute;
  bottom: 6rem;
  left: 1.5rem;
  width: 25rem;
  height: 5rem;
  padding: 1.5rem;
  color: #FFF;
  font-family: "Roboto";
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #FFF;
  box-shadow: 8px 6px 10px 0px rgba(0, 0, 0, 0.35);
}
@media (max-width: 768px) {
  .video-mob__btn {
    display: block;
  }
}

.heating-video {
  clip-path: circle(15% at 25% 75%);
  will-change: clip-path;
}
@media (max-width: 768px) {
  .heating-video {
    clip-path: unset;
  }
}

.services {
  padding-top: 12.6rem;
  padding-bottom: 12rem;
}
@media (max-width: 768px) {
  .services {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
.services__title {
  margin-bottom: 1.6rem;
}
.services__title::before {
  content: "Комплекс услуг";
}
@media (max-width: 768px) {
  .services__title {
    margin-bottom: 6rem;
  }
}
.services__img {
  stroke-width: 20px;
  stroke: #FFF;
  filter: drop-shadow(0.8rem 0.6rem 1rem rgba(0, 0, 0, 0.35));
}
.services__img img {
  height: 100%;
  width: 100%;
}
@media (max-width: 768px) {
  .services__img {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    margin-bottom: 4rem;
  }
}
.services__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 768px) {
  .services__container {
    grid-template-columns: 1fr;
  }
}
.services__list {
  padding-right: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: space-evenly;
}
@media (max-width: 768px) {
  .services__list {
    padding-right: unset;
  }
}
.services__item {
  padding-left: 2rem;
  position: relative;
  color: #403D3A;
  font-family: "Roboto";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 0.1rem solid #183475;
}
.services__item > span {
  max-width: 29rem;
}
@media (max-width: 768px) {
  .services__item > span {
    max-width: unset;
  }
}
@media (max-width: 768px) {
  .services__item:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.services__item::after {
  content: "";
  width: 6rem;
  height: 6rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .services__item {
    width: 100%;
  }
}
.services__item--deliv::after {
  background-image: url("./../img/services-icons/01.svg");
}
.services__item--set {
  margin-right: -5rem;
}
@media (max-width: 768px) {
  .services__item--set {
    maring-right: 0;
  }
}
.services__item--set::after {
  background-image: url("./../img/services-icons/02.svg");
}
.services__item--lau {
  margin-right: -10rem;
}
@media (max-width: 768px) {
  .services__item--lau {
    maring-right: 0;
  }
}
.services__item--lau::after {
  background-image: url("./../img/services-icons/03.svg");
}
.services__item--serv {
  margin-right: -15rem;
}
@media (max-width: 768px) {
  .services__item--serv {
    maring-right: 0;
  }
}
.services__item--serv::after {
  background-image: url("./../img/services-icons/04.svg");
}
.services__item--acc {
  margin-right: -20rem;
}
@media (max-width: 768px) {
  .services__item--acc {
    margin-right: 0;
  }
}
.services__item--acc::after {
  background-image: url("./../img/services-icons/05.svg");
}
.services__item--sup {
  margin-right: -25rem;
}
@media (max-width: 768px) {
  .services__item--sup {
    maring-right: 0;
  }
}
.services__item--sup::after {
  background-image: url("./../img/services-icons/06.svg");
}
.services__item--rem {
  margin-right: -30rem;
}
@media (max-width: 768px) {
  .services__item--rem {
    maring-right: 0;
  }
}
.services__item--rem::after {
  background-image: url("./../img/services-icons/07.svg");
}
.services__item--dem {
  margin-right: -35rem;
}
@media (max-width: 768px) {
  .services__item--dem {
    maring-right: 0;
  }
}
.services__item--dem::after {
  background-image: url("./../img/services-icons/08.svg");
}

.cta {
  position: relative;
  background-color: #183475;
  padding-top: 8.4rem;
  padding-bottom: 7rem;
}
@media (max-width: 768px) {
  .cta {
    padding-top: 5rem;
  }
}
.cta::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  max-height: 60rem;
  width: 72.3rem;
  background-image: url("./../img/logo-bg.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .cta::after {
    display: none;
  }
}
.cta__container {
  position: relative;
  z-index: 5;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;
}
@media (max-width: 768px) {
  .cta__container {
    grid-template-columns: 1fr;
  }
}
.cta__header {
  grid-column: 2/7 span;
}
@media (max-width: 768px) {
  .cta__header {
    grid-column: 1 span;
  }
}
.cta__form {
  grid-column: 4 span;
  padding-top: 3rem;
}
@media (max-width: 768px) {
  .cta__form {
    grid-column: 1 span;
  }
}
.cta__title {
  height: unset;
  align-items: flex-end;
  padding-left: 0;
  margin-bottom: 6.3rem;
}
@media (max-width: 768px) {
  .cta__title {
    margin-bottom: 2rem;
  }
}
.cta__title::before {
  content: "заявка";
  transform: unset;
  top: unset;
  bottom: -0.7rem;
  left: -12rem;
}
@media (max-width: 768px) {
  .cta__title::before {
    left: 1rem;
    top: -2.6rem;
    bottom: unset;
  }
}
.cta__desc {
  margin: 0;
  margin-bottom: 5rem;
  max-width: 72rem;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .cta__desc {
    max-width: unset;
  }
}
.cta__row {
  display: flex;
  align-items: center;
  gap: 4.5rem;
}
@media (max-width: 768px) {
  .cta__row {
    flex-wrap: wrap;
  }
}
.cta__row > a {
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (any-hover: hover) {
  .cta__row > a:hover {
    text-decoration: underline;
  }
}
.cta__row:not(:last-child) {
  margin-bottom: 3rem;
}
.cta__socials > a {
  width: 4rem;
  height: 4rem;
}
.cta__socials > a > svg {
  fill: #fff;
  width: 100%;
  height: 100%;
}

.form {
  position: relative;
}
.form._sending::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.2156862745);
  background-image: url("./../loader.gif");
  background-position: center center;
  background-size: 5rem 5rem;
  background-repeat: no-repeat;
}
.form > label {
  display: flex;
  align-items: center;
  width: 100%;
}
.form > label:not(:last-child) {
  margin-bottom: 5rem;
}
.form input[type=checkbox] {
  flex-shrink: 0;
  border: 0.1rem solid #183475;
  background: #FFF;
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
  cursor: pointer;
}
.form__input {
  width: 100%;
  padding: 1rem;
  background-color: unset;
  border: none;
  color: #fff;
  font-family: "Roboto";
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.form__input:focus {
  outline: none;
}
.form__input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.form__input:not([type=checkbox]) {
  border-bottom: 0.1rem solid #FFF;
}
.form__policy {
  cursor: pointer;
  margin: 0;
  color: #FFF;
  font-family: "Roboto";
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 2rem;
}
.form__policy > a {
  color: #235EED;
  text-decoration-line: underline;
}
.form__btn {
  text-align: center;
}
@media (max-width: 768px) {
  .form__btn {
    display: block;
    margin: 0 auto;
  }
}

.scheme {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.scheme__title::before {
  content: "Схема работ";
}
@media (max-width: 768px) {
  .scheme__title {
    margin-bottom: 3rem;
  }
}
.accord__item {
  padding: 1rem 0;
}
@media (min-width: 769px) {
  .accord__item:nth-child(even) .accord__line {
    margin-left: -2rem;
  }
}
@media (min-width: 769px) and (max-width: 768px) {
  .accord__item:nth-child(even) .accord__line {
    margin-left: unset;
  }
}
@media (min-width: 769px) {
  .accord__item:nth-child(even).active .accord__title::before {
    transform: rotate(90deg);
  }
  .accord__item:nth-child(even) .accord__title {
    order: -1;
    text-align: left !important;
    padding-left: 12rem;
    padding-right: 3rem !important;
  }
  .accord__item:nth-child(even) .accord__title::before {
    left: unset;
    right: 4.5rem;
    background-image: url("./../img/triangle-right.svg");
  }
  .accord__item:nth-child(even) .accord__desc {
    grid-column: 6/7 span;
  }
}
@media (min-width: 769px) {
  .accord__item:nth-child(odd) .accord__line {
    margin-right: -2rem;
  }
}
.accord__item.active .accord__desc {
  margin-bottom: 6rem;
}
.accord__item.active .accord__title::before {
  transform: rotate(-90deg);
}
.accord__row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;
}
@media (max-width: 768px) {
  .accord__row {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }
}
@media (max-width: 768px) {
  .accord__row--desc {
    grid-template-columns: 1fr;
  }
}
.accord__line {
  grid-column: 7 span;
  position: relative;
}
@media (max-width: 768px) {
  .accord__line {
    grid-column: 1 span;
  }
}
.accord__line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2.4rem;
  transform: translateY(-50%);
  width: 100%;
  height: 0.1rem;
  background-color: #183475;
}
@media (max-width: 768px) {
  .accord__line::before {
    top: 50%;
  }
}
.accord__header {
  cursor: pointer;
  padding: 1rem 0;
}
.accord__title {
  grid-column: 5 span;
  position: relative;
  margin: 0;
  grid-column: 5 span;
  padding-left: unset;
  padding-right: 12rem;
  color: #403D3A;
  font-family: "Oswald";
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: right;
}
@media (max-width: 768px) {
  .accord__title {
    grid-column: 2 span;
    padding-right: unset;
    font-size: 2rem;
  }
}
.accord__title::before {
  content: "";
  position: absolute;
  top: 1.1rem;
  left: 5rem;
  transform: translate(-50%, 0);
  width: 2.5rem;
  height: 3rem;
  background-image: url("./../img/triangle.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  transform-origin: center;
  transition: transform 0.1s ease-out;
}
@media (max-width: 768px) {
  .accord__title::before {
    left: 2rem;
    transform: translateY(-50%);
    top: 50%;
    transform-origin: left;
  }
}
.accord__desc {
  grid-column: 7 span;
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  transition: max-height 0.3s ease-out;
}
.accord__desc > p {
  margin: 0;
  color: #403D3A;
  font-family: "Roboto";
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.accord__desc > p:not(:last-child) {
  margin-bottom: 2rem;
}

.about {
  padding-bottom: 12rem;
}
.about__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
@media (max-width: 768px) {
  .about__container {
    grid-template-columns: 1fr;
    align-items: unset;
  }
}
.about__title::before {
  content: "О нас";
  left: 1.5rem;
}
.about__info {
  position: relative;
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
}
.about__images {
  height: 96rem;
  width: 100%;
}
@media (max-width: 768px) {
  .about__images {
    order: -1;
    margin-bottom: -30%;
    height: 44rem;
    width: 33rem;
    justify-self: flex-end;
  }
}
.about__img {
  position: relative;
  height: 100%;
  width: 100%;
}
.about__img img {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}

.about-info__content {
  position: relative;
  z-index: 5;
  padding: 7.6rem 3.2rem;
  background-color: #183475;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .about-info__content {
    padding: 4rem 1.8rem;
  }
}
.about-info__content > p {
  margin: 0;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-info__content > p:not(:last-child) {
  margin-bottom: 3rem;
}
@media (max-width: 768px) {
  .about-info__content > p {
    font-size: 2rem;
  }
}
.about-info__img {
  position: absolute;
  z-index: 1;
  right: -42rem;
  top: -3.4rem;
  height: calc(100% + 7.4rem);
  width: 58rem;
}
.about-info__img img {
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .about-info__img {
    height: 35.5rem;
    width: 26.6rem;
    right: unset;
    left: 0;
    top: -40%;
  }
}

.policy-section {
  position: relative;
  padding-top: 7.7rem;
}
.policy-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 77.9rem;
  height: 64.6rem;
  background-image: url("./../img/logo-bg.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.policy-section__header {
  margin: 0 auto;
  margin-bottom: 4rem;
  text-align: center;
}
.policy-section__header > h1 {
  margin: 0;
  margin-bottom: 2rem;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: "Oswald";
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.policy-section__header > h2 {
  margin: 0;
  color: #000;
  font-family: "Oswald";
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.policy-section__container {
  padding-bottom: 10rem;
}
.policy-section__container > h3 {
  margin: 0;
  color: #000;
  font-family: "Oswald";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.policy-section__container > h3:not(:last-child) {
  margin-bottom: 3rem;
}
.policy-section__container > p {
  margin: 0;
  color: #000;
  font-family: "Roboto";
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.policy-section__container > p:not(:last-child) {
  margin-bottom: 3rem;
}
.policy-section__container > ul > li {
  font-size: 2rem;
}

.footer {
  padding: 8.3rem 0;
  padding-bottom: 0;
  background-color: #1B1B1B;
}
@media (max-width: 768px) {
  .footer {
    padding-top: 5.6rem;
  }
}
.footer__container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;
  padding-bottom: 2rem;
}
@media (max-width: 768px) {
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    gap: unset;
  }
}
@media (max-width: 768px) {
  .footer__logo {
    grid-column: 2 span;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 4rem;
  }
}
.footer__logo svg {
  width: 100%;
  max-width: 9.6rem;
  fill: #fff;
  max-height: 3.7rem;
}
.footer .header__call {
  font-size: 1.2rem;
}
.footer__col {
  grid-column: 3 span;
  display: flex;
}
@media (max-width: 768px) {
  .footer__col {
    grid-column: 1 span;
  }
}
.footer__col--centered {
  justify-content: center;
}
@media (max-width: 768px) {
  .footer__col--centered {
    justify-content: start;
  }
}
.footer__item:not(:last-child) {
  margin-bottom: 3rem;
}
.footer__link {
  color: #FFF;
  font-family: "Roboto";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
@media (any-hover: hover) {
  .footer__link:hover {
    text-decoration: underline;
  }
}
.footer__address {
  grid-column: 2 span;
}
@media (max-width: 768px) {
  .footer__address {
    grid-column: 1 span;
    align-self: flex-end;
  }
}
.footer__address p {
  margin: 0;
  color: #FFF;
  font-family: "Roboto";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer__address p:not(:last-child) {
  margin-bottom: 3rem;
}

.footer-socials__item--tel {
  margin-bottom: 1.5rem;
}
.footer-socials__item--tel a {
  font-size: 2.4rem;
}
.footer-socials__item--call {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}
.footer-socials__item--call svg {
  margin-right: 0.5rem;
}
.footer-socials__item--call a {
  font-size: 1.2rem;
}
.footer-socials__item--mail {
  font-size: 1.5rem;
  margin-bottom: 1.8rem;
}
.footer-socials__item--tg, .footer-socials__item--wh {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
}
.footer-socials__item--tg svg, .footer-socials__item--wh svg {
  width: 100%;
  height: 100%;
}
.footer-socials__item--tg {
  margin-right: 4.2rem;
}

.footer-copy {
  padding: 3rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 768px) {
  .footer-copy {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
}
.footer-copy__link {
  color: #FFF;
  font-family: "Roboto";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (any-hover: hover) {
  .footer-copy__link:hover {
    text-decoration: underline;
  }
}

.modal {
  position: fixed;
  z-index: 40;
  inset: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in;
  transform: translateY(100%);
}

.modal-form {
  position: fixed;
  z-index: 99;
  right: 6rem;
  bottom: 0;
  width: 40rem;
  background-color: #403D3A;
  padding: 2rem;
  padding-bottom: 3rem;
}
@media (max-width: 768px) {
  .modal-form {
    right: 50%;
    transform: translateX(50%);
  }
}
.modal-form > h3 {
  margin: 0;
  margin-bottom: 1.8rem;
  color: #FFF;
  font-family: "Oswald";
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.modal-form .form__inputs {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.modal-form .form__btn {
  display: block;
  margin: 0 auto;
}
.modal-form > p {
  margin: 0;
  margin-bottom: 1rem;
  color: #FFF;
  font-family: "Roboto";
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-last__label {
  margin-bottom: 2rem !important;
}

.mob-extra {
  display: none;
  border-top: 0.1rem solid #183475;
  border-bottom: 0.1rem solid #183475;
  padding-top: 6rem;
  padding-bottom: 6rem;
}
@media (max-width: 768px) {
  .mob-extra {
    display: block;
  }
}
.mob-extra__container {
  padding: 0 1.5rem;
}
.mob-extra__title {
  margin-bottom: 4rem;
}
.mob-extra__title::before {
  content: "Котельная";
}
.mob-extra__desc {
  margin: 0;
  margin-bottom: 4rem;
  color: #1B1B1B;
  font-family: "Roboto";
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mob-extra__item {
  display: flex;
  align-items: flex-end;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mob-extra__item:not(:last-child) {
  margin-bottom: 4rem;
}
.mob-extra__item > span {
  color: #183475;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  text-shadow: 0.8rem 0.6rem 1rem rgba(0, 0, 0, 0.35);
  font-family: "Oswald";
  font-size: 12.8rem;
  line-height: 1;
}
.mob-extra__item > p {
  margin: 0;
  color: #1B1B1B;
  font-family: "Roboto";
  font-size: 2.6rem;
}

.menu {
  position: fixed;
  inset: 0;
  background-color: #334161;
  z-index: 80;
  overflow: scroll;
  transform: translateX(100%);
  display: none;
  transition: transform 0.3s ease-in;
}
.menu__close {
  width: 20px;
  height: 30px;
  position: absolute;
  top: 2rem;
  right: 5.3rem;
  border: none;
  background-color: transparent;
}
.menu__close span {
  position: absolute;
  display: block;
  background-color: #fff;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  transform: rotate(-45deg);
}
.menu__close span:nth-child(2) {
  transform-origin: center;
  transform: rotate(45deg);
}
.menu.active {
  transform: translateX(0);
}
@media (max-width: 768px) {
  .menu {
    display: block;
  }
}
.menu .header__item {
  width: 100%;
  border-bottom: 1px solid #fff;
}
.menu .socials {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;
  margin: 1rem;
}
.menu .header__call {
  border-bottom: 1px solid #fff;
}
.menu .nav__item {
  position: relative;
  border-bottom: 1px solid #fff;
}