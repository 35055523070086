.socials {
  &__link {
    display: block;
    width: 4rem;
    height: 4rem;

    svg {
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }
}