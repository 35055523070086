.nav {

  &__list {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    flex: 1;
    text-align: center;
    transition: background-color .3s ease-in;
    height: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include hover {
      background-color: #334161;
    }
  }

  &__link {
    display: inline-block;
    padding: 1rem;
    padding-top: 0.8rem;
    color: #FFF;
    font-family: Roboto;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: color .3s ease-in;

    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }


}