.cta {
  position: relative;
  background-color: #183475;
  padding-top: 8.4rem;
  padding-bottom: 7rem;

  @include mobile {
    padding-top: 5rem;
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    max-height: 60rem;
    width: 72.3rem;

    background-image: url('./../img/logo-bg.svg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @include mobile {
      display: none;
    }
  }

  &__container {
    position: relative;
    z-index: 5;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__header {
    grid-column: 2 / 7 span;

    @include mobile {
      grid-column: 1 span;
    }
  }

  &__form {
    grid-column: 4 span;
    padding-top: 3rem;

    @include mobile {
      grid-column: 1 span;
    }
  }

  &__title {
    height: unset;
    align-items: flex-end;
    padding-left: 0;
    margin-bottom: 6.3rem;

    @include mobile {
      margin-bottom: 2rem;
    }

    &::before {
      content: 'заявка';
      transform: unset;
      top: unset;
      bottom: -0.7rem;
      left: -12rem;

      @include mobile {
        left: 1rem;
        top: -2.6rem;
        bottom: unset;
      }
    }
  }

  &__desc {
    margin: 0;
    margin-bottom: 5rem;
    max-width: 72rem;
    color: #FFF;
    font-family: "Roboto";
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include mobile {
      max-width: unset;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 4.5rem;

    @include mobile {
      flex-wrap: wrap;
    }

    &>a {
      color: #FFF;
      font-family: "Roboto";
      font-size: 2.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @include hover {
        text-decoration: underline;
      }
    }




    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__socials {
    &>a {
      width: 4rem;
      height: 4rem;

      &>svg {
        fill: #fff;
        width: 100%;
        height: 100%;
      }
    }


  }
}


.form {
  position: relative;

  &._sending {
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 100;
      background-color: #00000037;
      background-image: url('./../loader.gif');
      background-position: center center;
      background-size: 5rem 5rem;
      background-repeat: no-repeat;
    }
  }

  &>label {
    display: flex;
    align-items: center;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 5rem;
    }
  }

  input[type="checkbox"] {
    flex-shrink: 0;
    border: 0.1rem solid #183475;
    background: #FFF;
    box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
    cursor: pointer;
  }

  &__input {
    width: 100%;
    padding: 1rem;
    background-color: unset;
    border: none;

    color: #fff;
    font-family: "Roboto";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.30);
    }

    &:not([type="checkbox"]) {
      border-bottom: 0.1rem solid #FFF;
    }
  }

  &__policy {
    cursor: pointer;
    margin: 0;
    color: #FFF;
    font-family: "Roboto";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 2rem;

    &>a {
      color: #235EED;
      text-decoration-line: underline;
    }
  }

  &__btn {
    text-align: center;

    @include mobile {
      display: block;
      margin: 0 auto;
    }
  }
}