.benefits {

  &__slider-wrapper {
    width: 100%;
  }

  &__slider {
    display: none;
    height: 25.5rem;

    .swiper-slide {
      width: auto;
    }

    @include mobile {
      display: block;
    }
  }

  &__slider-control {
    display: none;


    padding-top: 2.5rem;
    padding-bottom: 6.1rem;

    align-items: center;
    justify-content: center;
    gap: 0.7rem;

    @include mobile {
      display: flex;
    }

    .benefits-button-prev,
    .benefits-button-next {
      position: relative;
      width: 1.8rem;
      height: 1.8rem;
      transform: rotate(45deg);
    }

    .benefits-button-prev {
      border-left: 0.2rem solid #183475;
      border-bottom: 0.2rem solid #183475;
    }

    .benefits-button-next {
      border-top: 0.2rem solid #183475;
      border-right: 0.2rem solid #183475;
    }

    .benefits-pagination {
      width: unset;
      color: #183475;
      font-family: "Oswald";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__row {
    height: 100%;
    padding-left: 37rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    transition: linear;
    will-change: transform;
    counter-reset: counter 0;

    @include mobile {
      padding-left: unset;
      flex-wrap: wrap;
    }
  }

  &__col {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  &__items {
    display: flex;
    height: 100%;

    @include mobile {
      display: none;
    }
  }

  &__header {
    height: 100%;
    position: relative;
    max-width: 80rem;
    flex-shrink: 0;
    margin-right: 14rem;
    display: flex;
    flex-direction: column;


    @include mobile {
      text-align: center;
      flex-shrink: 1;
      padding: 0 1.5rem;
      margin-right: 0;
      margin-bottom: 8rem;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: 10rem;
    margin-bottom: 5rem;
    height: 15rem;
    width: 100%;
    position: relative;
    z-index: 2;
    color: #403D3A;
    font-family: "Oswald";
    font-size: 4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
    text-align: left;

    @include mobile {
      height: unset;
      margin-bottom: 4rem;
      display: block;
    }

    &::after {
      content: "Преимущества";
      position: absolute;
      z-index: -1;
      left: 0%;
      top: 50%;
      transform: translate(-12rem, -50%);
      color: rgba(24, 52, 117, 0.05);
      leading-trim: both;
      text-edge: cap;
      font-family: "Oswald";
      font-size: 18.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;

      @include mobile {
        display: block;
        font-size: 6.4rem;
        left: 1rem;
        top: -2.6rem;
        margin-left: -1.5rem;
        transform: unset;
      }
    }
  }

  &__heater {
    position: absolute;
    bottom: -1rem;
    left: 37rem;
    width: 88.5rem;

    img {
      height: 100%;
    }

    @include mobile {
      position: relative;
      left: unset;
      bottom: unset;
      order: 0;
      padding: 0 1.5rem;
    }
  }

  &__btn {

    @include mobile {
      align-self: center;
    }
  }

  &__desc {
    margin: 0;
    margin-bottom: 8rem;
    color: #403D3A;
    font-family: "Roboto";
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;

    @include mobile {
      margin-bottom: 5rem;
      font-size: 2rem;
    }
  }
}


.benefit {
  counter-increment: counter;
  width: 62.2rem;
  height: 54rem;
  padding: 5.8rem 3.3rem;
  box-shadow: 0.8rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.35);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  transition: background-color .3s ease;

  @include mobile {
    padding: 0;
    width: 100%;
    height: 100%;
  }

  @include hover {
    svg {
      fill: none;
      stroke: #fff;
    }

    p {
      color: #fff;
    }

    &::before {
      color: rgba(255, 255, 255, 0.5);
    }

    background-color: #183475;

    &::after {}
  }

  &::before {
    content: counter(counter);
    position: absolute;
    z-index: 1;
    top: 3rem;
    left: 3rem;
    color: rgba(24, 52, 117, 0.50);
    leading-trim: both;
    text-edge: cap;
    font-family: "Oswald";
    font-size: 18.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.75;
    text-transform: uppercase;

    @include mobile {
      left: unset;
      top: 0;
      right: 0;
    }
  }

  svg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20rem;
    height: 20rem;
    fill: #fff;

    transition: fill .3s ease-in;
  }

  // &::after {
  //   content: "";


  //   background-position: center center;
  //   background-size: cover;
  //   background-repeat: no-repeat;

  //   transition: transform .3s ease-in;
  // }

  &>p {
    margin: 0 auto;

    font-family: "Roboto";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #403D3A;
    max-width: 39.4rem;


    @include mobile {
      padding: 3.853rem;
      padding-left: 3.4rem;
      padding-right: 2.8rem;
    }
  }

  &--blue {
    background: #183475;

    @include hover {
      background-color: #fff;

      p {
        color: #1B1B1B;
      }

      svg {
        fill: #1b1b1b;
      }

      &::before {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &::before {
      color: rgba(255, 255, 255, 0.1);
    }

    &>p {
      color: #FFF;
    }
  }

  &--dark {
    background: #1B1B1B;

    @include hover {
      background-color: #fff;

      p {
        color: #1B1B1B;
      }

      svg {
        fill: #1b1b1b;
      }

      &::before {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &>p {
      max-width: 37rem;
    }

    &::before {
      color: rgba(255, 255, 255, 0.1);
    }


    &>p {
      color: #FFF;
    }
  }


  &--1 {
    &::after {
      background-image: url('./../img/benefits/01.svg');
    }
  }

  &--2 {
    &::after {
      background-image: url('./../img/benefits/02.svg');
    }
  }

  &--3 {
    svg {
      stroke: #183475;
    }

    &::after {
      background-image: url('./../img/benefits/03.svg');
    }
  }

  &--4 {
    &::after {
      background-image: url('./../img/benefits/04.svg');
    }
  }

  &--5 {
    &::after {
      background-image: url('./../img/benefits/05.svg');
    }
  }

  &--6 {
    &::after {
      background-image: url('./../img/benefits/06.svg');
    }
  }
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2rem);
  }

  100% {
    transform: translateY(0);
  }
}