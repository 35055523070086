.footer {
  padding: 8.3rem 0;
  padding-bottom: 0;
  background-color: #1B1B1B;

  @include mobile {
    padding-top: 5.6rem;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;
    padding-bottom: 2rem;

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-start;
      gap: unset;
    }
  }

  &__logo {
    @include mobile {
      grid-column: 2 span;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 4rem;
    }

    svg {
      width: 100%;
      max-width: 9.6rem;
      fill: #fff;
      max-height: 3.7rem;
    }
  }

  .header__call {
    font-size: 1.2rem;
  }

  &__col {
    grid-column: 3 span;
    display: flex;

    @include mobile {
      grid-column: 1 span;
    }

    &--centered {
      justify-content: center;

      @include mobile {
        justify-content: start;
      }
    }
  }

  &__list {}

  &__item {

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    color: #FFF;
    font-family: "Roboto";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;

    @include hover {
      text-decoration: underline;
    }
  }

  &__address {
    grid-column: 2 span;

    @include mobile {
      grid-column: 1 span;
      align-self: flex-end;
    }

    p {
      margin: 0;
      color: #FFF;
      font-family: "Roboto";
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }
}


.footer-socials {

  &__item {
    &--tel {
      a {
        font-size: 2.4rem;
      }

      margin-bottom: 1.5rem;
    }

    &--call {
      display: flex;
      align-items: center;
      margin-bottom: 2.4rem;

      svg {
        margin-right: 0.5rem;
      }

      a {
        font-size: 1.2rem;
      }
    }

    &--mail {
      font-size: 1.5rem;
      margin-bottom: 1.8rem;
    }

    &--tg,
    &--wh {
      display: inline-block;
      width: 5rem;
      height: 5rem;
      border-radius: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &--tg {
      margin-right: 4.2rem;
    }
  }
}

.footer-copy {
  padding: 3rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.50);
  display: flex;
  align-items: center;
  gap: 5rem;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

  &__link {
    color: #FFF;
    font-family: "Roboto";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include hover {
      text-decoration: underline;
    }
  }
}