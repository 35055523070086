.services {
  padding-top: 12.6rem;
  padding-bottom: 12rem;

  @include mobile {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  &__title {
    margin-bottom: 1.6rem;

    &::before {
      content: "Комплекс услуг"
    }

    @include mobile {
      margin-bottom: 6rem;
    }
  }

  &__img {
    stroke-width: 20px;
    stroke: #FFF;
    filter: drop-shadow(0.8rem 0.6rem 1rem rgba(0, 0, 0, 0.35));

    img {
      height: 100%;
      width: 100%;
    }

    @include mobile {
      margin-left: -1.6rem;
      margin-right: -1.6rem;
      margin-bottom: 4rem;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__list {
    padding-right: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    justify-content: space-evenly;

    @include mobile {
      padding-right: unset;
    }
  }

  &__item {
    &>span {
      max-width: 29rem;

      @include mobile {
        max-width: unset;
      }
    }

    padding-left: 2rem;
    position: relative;
    color: #403D3A;
    font-family: "Roboto";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    border-bottom: 0.1rem solid #183475;

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 2rem;
      }
    }

    &::after {
      content: '';
      width: 6rem;
      height: 6rem;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    @include mobile {
      width: 100%;
    }

    &--deliv {
      &::after {
        background-image: url('./../img/services-icons/01.svg');
      }
    }

    &--set {
      margin-right: -5rem;

      @include mobile {
        maring-right: 0
      }

      &::after {
        background-image: url('./../img/services-icons/02.svg');
      }
    }

    &--lau {
      margin-right: -10rem;

      @include mobile {
        maring-right: 0
      }

      &::after {
        background-image: url('./../img/services-icons/03.svg');
      }
    }

    &--serv {
      margin-right: -15rem;

      @include mobile {
        maring-right: 0
      }

      &::after {
        background-image: url('./../img/services-icons/04.svg');
      }
    }

    &--acc {
      margin-right: -20rem;

      @include mobile {
        margin-right: 0
      }

      &::after {
        background-image: url('./../img/services-icons/05.svg');
      }
    }

    &--sup {
      margin-right: -25rem;

      @include mobile {
        maring-right: 0
      }

      &::after {
        background-image: url('./../img/services-icons/06.svg');
      }
    }

    &--rem {
      margin-right: -30rem;

      @include mobile {
        maring-right: 0
      }

      &::after {
        background-image: url('./../img/services-icons/07.svg');
      }
    }

    &--dem {
      margin-right: -35rem;

      @include mobile {
        maring-right: 0
      }

      &::after {
        background-image: url('./../img/services-icons/08.svg');
      }
    }
  }

}